<template>
  <div>
    <c-tab
      type="vertical"
      :tabItems.sync="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
      @tabClick="tabClick"
    >
    <!-- :workPlanDatas.sync="workPlanDatas" -->
          <!-- @saveWorkPlan="saveWorkPlan" -->
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :param.sync="param"
          :click.sync="click"
          :disabledObj.sync="disabledObj"
          :height.sync="height"
          @setKey="setKey"
          @disabledSet="disabledSet"
          @stepBystep2="stepBystep2"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
export default {
  name: 'work-order-plan01',
  props: {
    param: {
      type: Object,
      default: () => ({
        newEquipmentCd: '',
        newTitle: '',
        newWoWorkTypeCd: '', // 작업유형 (PM)
        workPlanId: '',
        woRequestId: '',
        plantCd: null,
        woWorkPlanStepCd: '',
        copyFlag: 'N',
        firstCopy: false,
        dailyCheckId: '',
        minEquipmentCheckId: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      // workPlanDatas: [],
      splitter: 5,
      tab: 'planInfo',
      editable: true,
      detailUrl: '',
      completeUrl: '',
      deleteUrl: '',
      isComplete: false,
      disabledObj: {
        disabled: false,
      },
      click: '',
      tabItems: [
        { key: uid(), name: 'planInfo', icon: 'edit', label: '오더내역', component: () => import(`${'./workOrderPlanDirection.vue'}`) },
      ],
      addTabItems: [
        { key: uid(), name: 'planWork', icon: 'engineering', label: '작업계획', component: () => import(`${'./workOrderPlanWork.vue'}`) },
        { key: uid(), name: 'attach', icon: 'add_to_photos', label: '첨부파일', component: () => import(`${'./workOrderPlanFile.vue'}`) },
      ],
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      return numHeight + 'px';
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;

      if (this.param.workPlanId) {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
    },
    stepBystep2(_param) {
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: {
          stepCd: _param.stepCd,
          woWorkPlanStepCd: _param.woWorkPlanStepCd,
          suffixChip: _param.suffixChip
        }
      })
    },
    closePopup() {
      this.$emit('emitStep', {
        name: 'closePopup'
      })
    },
    setKey(workPlanId) {
      this.$set(this.param, 'workPlanId', workPlanId)
      this.tabItems = this.tabItems.concat(this.addTabItems);
      // this.$set(this.tabItems[3], 'disabled', false)
      // this.$set(this.tabItems[4], 'disabled', false)
    },
    // saveWorkPlan(_works, _equips) {
    //   this.workPlanDatas = [];
    //   let equips = [];
    //   this.$_.forEach(_equips, _item => {
    //     equips.push(_item.equipmentCd);
    //   });
    //   let cnt = 0;
    //   this.$_.forEach(_works, item => {
    //     this.workPlanDatas.push({
    //       name: 'tab' + cnt,
    //       label: item.sortOrder + '.(' + item.workOprTypeName + ') ' + item.workPlanWorkName,
    //       param: {workPlanId: item.workPlanId, workPlanWorkId: item.workPlanWorkId, equips: equips, worksData: item},
    //       component: () => import(`${'./workOrderPlanService.vue'}`),
    //       key: uid(),
    //     })
    //     cnt++;
    //   });
    // },
    disabledSet(_bool) {
      this.disabledObj.disabled = _bool;
    },
    tabClick() {
      this.click = uid();
    }
  }
};
</script>
